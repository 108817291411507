<template>
  <div>
    <el-table :data="info" style="width: 100%" height="500">
      <el-table-column fixed prop="id" label="ID" width="200"></el-table-column>
      <el-table-column prop="name" label="姓名" width="200"></el-table-column>
      <el-table-column prop="sex" label="性别" width="200"></el-table-column>
      <el-table-column prop="age" label="年龄" width="200"></el-table-column>
      <el-table-column
        prop="address"
        label="家庭住址"
        width="300"
      ></el-table-column>
      <el-table-column
        prop="tel"
        label="联系电话"
        width="300"
      ></el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handleEdit(scope.row)"
            >编辑</el-button
          >
          <el-button type="text" size="small" @click="handleDel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-button type="primary" @click="handleAdd">添 加</el-button>
    <update :isshow="isshow" :form="form"></update>
  </div>
</template>

<script>
import update from "./update.vue";
export default {
  data() {
    return {
      info: [],
      form: {},
      isshow: {
        show: false,
        tianjia: false,
        xiugai: false,
      },
    };
  },

  components: {
    update,
  },

  methods: {
    handleEdit(item) {
      this.isshow.show = true;
      this.isshow.xiugai = false;
      this.isshow.tianjia = true;
      this.form = item;
    },
    handleAdd() {
      this.isshow.show = true;
      this.isshow.xiugai = true;
      this.isshow.tianjia = false;
      this.form = {};
    },
    handleDel(item) {
      this.axios
        .get("/list/del", {
          params: {
            id: item.id
          }
        })
        .then((res) => {
          if (res.data.state == 200) {
            this.getall();
            this.$message({
              message: "删除成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "删除失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log("操作失败" + err);
        });
    },
    getall() {
      this.axios
        .get("/list/all")
        .then((res) => {
          this.info = res.data;
        })
        .catch((err) => {
          console.log("获取数据失败" + err);
        });
    },
  },
  watch: {
    "isshow.show": function (newVal) {
      this.getall();
    },
  },
  created() {
    this.getall();
  },
};
</script>

<style scoped>
</style>