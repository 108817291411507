<template>
  <div>
    <el-dialog title="编辑窗口" :visible.sync="isshow.show" :close-on-click-modal="false">
      <el-form :model="form">
        <el-form-item label="ID" :label-width="formLabelWidth">
          <el-input
            v-model="form.id"
            autocomplete="off"
            :disabled="isshow.tianjia"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="家庭住址" :label-width="formLabelWidth">
          <el-input v-model="form.address" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" :label-width="formLabelWidth">
          <el-input v-model="form.tel" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="update" :disabled="isshow.xiugai"
          >修改</el-button
        >
        <el-button type="primary" @click="add" :disabled="isshow.tianjia"
          >添 加</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formLabelWidth: "85px",
    };
  },
  props: {
    isshow: Object,
    form: {
      type: Object,
      defalut: "",
    },
  },
  methods: {
    close() {
      this.$parent.isshow.show = false;
    },
    update() {
      this.axios
        .post("/list/update", this.form)
        .then((res) => {
          if (res.data.state == 200) {
            this.close();
            this.$message({
              message: "修改成功",
              type: "success",
            });
          } else {
            console.log(res.data);
            this.$message({
              message: "修改失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log("操作失败：" + err);
        });
    },
    add() {
      this.axios
        .post("/list/add", this.form)
        .then((res) => {
          if (res.data.state == 200) {
            this.close();
            this.$message({
              message: "添加成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "添加失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log("操作失败：" + err);
        });
    },
  },
};
</script>

<style scoped>
</style>